<template>
  <div
    class="sm:bg-container"
    style="
      background-image: url(&quot;https://res.cloudinary.com/ds9ewjix6/image/upload/v1716556772/pricing/520aed99-c034-4489-817a-7b56a8ffbd78.png&quot;);
    "
  >
    <div class="min-h-screen sm:py-2 flex items-start">
      <div
        class="sm:mx-4 w-full sm:w-2/3 md:w-1/2 xl:w-1/3 bg-white sm:bg-transparent"
      >
        <Card class="h-screen sm:h-[calc(100vh-1rem)] p-8">
          <slot />
        </Card>
      </div>
    </div>
  </div>
</template>
<style scoped>
.bg-container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}
</style>
